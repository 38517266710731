// src/App.tsx
import React from 'react';
import Header from './components/Header';
import Gallery from './components/Gallery';
import Footer from './components/Footer';

const App: React.FC = () => {
  return (
    <div className="bg-gray-900 text-white">
      <Header />
      <main className="container mx-auto px-4 py-8">
        <Gallery />
      </main>
      <Footer />
    </div>
  );
};

export default App;