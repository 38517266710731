// src/components/Gallery.tsx
import React from 'react';

const Gallery: React.FC = () => {
  // Supposons que vous ayez des liens vers vos images astro
  const astroImages = [
    'assets/images/gallery/astro (1).png',
    'assets/images/gallery/astro (2).png',
    'assets/images/gallery/astro (3).png',
    'assets/images/gallery/astro (4).png',
    'assets/images/gallery/astro (5).png',
    'assets/images/gallery/astro (6).png',
    'assets/images/gallery/astro (7).png',
    'assets/images/gallery/astro (8).png',
    'assets/images/gallery/astro (9).png',
    'assets/images/gallery/astro (10).png',
    'assets/images/gallery/astro (11).png',
    // Ajoutez d'autres liens d'images ici
  ];

  return (
    <div className="container mx-auto mt-8 grid gap-4 grid-cols-1 sm:grid-cols-2 md:grid-cols-3">
      {astroImages.map((imageUrl, index) => (
        <img key={index} src={imageUrl} alt={`Image Astro ${index + 1}`} className="rounded-lg shadow-lg" />
      ))}
    </div>
  );
};

export default Gallery;